import * as React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Title } from '../components/Title'
import { Text } from '../components/Text'
import { TeamGrid } from '../components/TeamGrid'
import { Styled } from '../styles/equipa.styles'
import { graphql } from 'gatsby'

const EquipaPage = ({ data: { equipaJson: content, miscJson: misc }, location }) => {
  return (
    <Layout location={location}>
      <SEO title="A nossa Equipa" description={content.seoDescription} />
      <Styled.Container>
        <div
          className="background"
          style={{ backgroundImage: `url(${content.backgroundImg})` }}
        >
          <Title text={content.mainTitle} className="main-title" />
          <img src={content.teamImg} className="team-image" alt="Equipa" />
        </div>

        <section className="about bg-blue">
          <div className="about__text">
            <Title text={content.infoTitle} />
            <Text text={content.infoText} />
          </div>
          <div className="about__video">
            <iframe
              src={content.infoVideo}
              title={content.infoTitle}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          </div>
        </section>

        <section
          className="team"
          style={{ backgroundImage: `url(${content.backgroundImg})` }}
        >
          <Title text={content.teamTitle} />
          <div className="desktop">
            <TeamGrid
              isHighlight
              invertFirst
              members={misc.teamMembers.filter(
                (member) => member.highlight && !member.hideOnTeam
              )}
            />
            <TeamGrid team={true}
              members={misc.teamMembers.filter(
                (member) => !member.highlight && !member.hideOnTeam
              )}
            />
          </div>

          <div className="mbl">
          <TeamGrid
              isHighlight
              invertFirst
              members={misc.teamMembers.filter(
                (member) => member.highlight && !member.hideOnTeam
              )}
            />
            <TeamGrid 
              members={misc.teamMembers.filter(
                (member) => !member.highlight && !member.hideOnTeam
              )}
            />
          </div>
        </section>
      </Styled.Container>
    </Layout>
  )
}

export default EquipaPage

export const pageQuery = graphql`
  query {
    equipaJson {
      seoDescription
      mainTitle
      teamImg
      backgroundImg
      infoTitle
      infoText
      infoVideo
      teamTitle
    }
    miscJson {
      teamMembers {
        name
        role
        omd
        text
        img
        specialty
        highlight
        hideOnTeam
        areas{
          btn
          btnLink
        }
      }
    }
  }
`
