import React, { useState, useEffect } from 'react'
import { TeamMember } from './TeamMember'
import styled from 'styled-components'
import Slider from "react-slick";


export const TeamGrid = ({ members, isHighlight, invertFirst, team }) => {
  const [activeSlide, setActiveSlide] = useState(1)
  const [totalSlides, setTotalSlides] = useState(null)

  useEffect(() => {
    setTotalSlides(members ? members.length : 0)
  }, [])


  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <svg id="Group_634" data-name="Group 634" width="15" height="26.652" viewBox="0 0 15 26.652" onClick={onClick} className={className}>
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_722" data-name="Rectangle 722" width="15" height="26.652" fill="#053d7f" />
          </clipPath>
        </defs>
        <g id="Group_618" data-name="Group 618" clip-path="url(#clip-path)">
          <path id="Path_6988" data-name="Path 6988" d="M11.316,13.189C9.451,11.331,7.642,9.53,5.835,7.727Q3.374,5.271.918,2.81A1.6,1.6,0,0,1,.7.467c.662-.687,1.569-.613,2.387.2q5.6,5.6,11.2,11.2c.973.975.948,1.7-.043,2.686Q8.561,20.235,2.889,25.918c-.1.1-.2.208-.306.3A1.515,1.515,0,0,1,.45,26.207a1.5,1.5,0,0,1-.009-2.13c1.073-1.122,2.187-2.206,3.285-3.3,2.5-2.5,5-5,7.59-7.584" transform="translate(0 0)" fill="#053d7f" />
        </g>
      </svg>

    );
  }
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <svg id="Group_632" data-name="Group 632" width="15" height="26.652" viewBox="0 0 15 26.652" onClick={onClick} className={className}>
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_722" data-name="Rectangle 722" width="15" height="26.652" fill="#053d7f" />
          </clipPath>
        </defs>
        <g id="Group_618" data-name="Group 618" clip-path="url(#clip-path)">
          <path id="Path_6988" data-name="Path 6988" d="M3.685,13.189C5.55,11.331,7.359,9.53,9.166,7.727q2.461-2.456,4.917-4.917A1.6,1.6,0,0,0,14.3.467c-.662-.687-1.569-.613-2.387.2q-5.6,5.6-11.2,11.2c-.973.975-.948,1.7.043,2.686Q6.44,20.235,12.112,25.918c.1.1.2.208.306.3a1.515,1.515,0,0,0,2.133-.013,1.5,1.5,0,0,0,.009-2.13c-1.073-1.122-2.187-2.206-3.285-3.3-2.5-2.5-5-5-7.59-7.584" transform="translate(0 0)" fill="#053d7f" />
        </g>
      </svg>

    );
  }


  var settingsSliderTeam = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };


  if (team && !isHighlight)
    settingsSliderTeam.slidesToShow = 3
  else if (totalSlides <= 1) {
    settingsSliderTeam = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
    };
  }





  return (
    <StyledTeamGrid className={isHighlight ? 'container-small' : ''}>
      <Slider {...settingsSliderTeam}>
        {members.map((member, index) => (
          <div className={totalSlides <= 1 ? 'container-slide-team max-width-full' : 'container-slide-team'} key={'slide-team-' + index}>
            <TeamMember
              member={member}
              key={member.name}
              name={member.name}
              role={member.role}
              omd={member.omd}
              areas={member.areas}
              img={member.img}
              big={isHighlight}
            />
          </div>
        ))}
      </Slider>
    </StyledTeamGrid>

  )
}

const StyledTeamGrid = styled.div`
  max-width: 95%;
  margin: auto;
  .max-width-full{
    max-width: initial !important;
  }
  .container-small{
    max-width: 110vh !important;
    margin-top: 5vh !important;
  }
  @media screen and (max-width: 1000px) {
  

  }
`
