import React from 'react'
import { Text } from './Text'
import styled from 'styled-components'
import { Link } from 'gatsby'

export const TeamMember = ({ name, role, omd, areas, img, big, member }) => {
  return (
    <StyledTeamMember big={big} className="team-member">
      <img src={img} alt={name} />
      <div className="content">
        <h3 className="big">{name}</h3>
        <div className="info">
          <h5 className="gold uppercase">{role} {omd}</h5>
        </div>
        {big ?
          <>
            {areas.length > 0 && (
              <>
                <Link to={member.areas[0].btnLink} className="btn-blue-border" >
                  {member.areas[0].btn}
                </Link>
                <Link to={member.areas[2].btnLink} className="btn-blue-border" >
                  {member.areas[2].btn}
                </Link>
              </>
            )}
          </>
          :
          <>
            {areas.length > 0 && areas.map((area, index) => (
              <Link to={area.btnLink} className="btn-blue-border" key={'area-' + area.btn}>
                {area.btn}
              </Link>
            ))}
          </>}

      </div>
    </StyledTeamMember>
  )
}

const StyledTeamMember = styled.div`
  display: block;
  margin: auto;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: ${(props) => (props.big ? '0 auto' : 'unset')};
  padding-bottom: 1vh;


  .uppercase{
    text-transform: uppercase;
  }

  img {
    height: 45vh;
    margin: auto;
    width: 40vh;
    object-fit: cover;
    object-position: top;
    margin-bottom: 2vh;
  }

  .content {
    margin: auto;
    max-width: 45vh;
  }

  h3 {
    position: relative;
    font-family: ${({ theme }) => theme.fontFamily};
    position: relative;
    font-size: 3rem;
    line-height: 3.3rem;
    font-weight: 300;

    &:after {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      width: 10rem;
      background: inherit;
    }

  }

  .info {
    

    h5 {
      font-size: 2rem;
      line-height: 2.1rem;
      font-weight: 400;
      letter-spacing: 1px;
      padding-top: 0.5vh;
    }

    .text {
      font-size: 1.5rem;
      line-height: 2rem;
      color: ${({ theme }) => theme.blue};
      margin-top: ${({ theme }) => theme.spacingXS};
      min-width: 18rem;
      max-width: 35rem;
    }
  }

  .btn-blue-border{
    color: ${({ theme }) => theme.blue};
    border: 1px solid ${({ theme }) => theme.blue};
    font-size: 1.5rem;
    border-radius: 151px;
    display: inline-flex;
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 0.2vh;
    margin-left: 0.2vh;
    line-height: 3rem;
    transition: all 0.25s ;
    margin-top: 1vh;

    :hover{
      color: ${({ theme }) => theme.white};
      background-color: ${({ theme }) => theme.gold};
      border-color: ${({ theme }) => theme.gold};
    }
  }

  @media screen and (max-width: 1000px) {
    max-width: 90%;

    img {
      height: 25vh;
      width: 20vh;
      margin-bottom: 1vh;
    }

    .btn-blue-border{
      margin-top: 0.6vh;
      line-height: 1.5;
    }


    .content {
      width: 20vh;
      h3 {
        font-size: 2.5rem;
        line-height: 1vh;
      }

      .info {
        margin: 0;
        h5 {
          font-size: 2rem;
          line-height: 1;
        }

        p {
          font-size: 2rem;
          line-height: 2.8rem;
          margin-top: ${({ theme }) => theme.spacingXS};
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .content {
      h3 {
        font-size: 2rem;
      }
    }
  }

  @media screen and (max-width: 450px) {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 350px) {
    img {
      height: 25rem;
    }

    .content {
      h3 {
        font-size: 1.7rem;
        line-height: 2.4rem;
      }

      .info h5 {
        font-size: 1.6rem;
      }

      .info p {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
  }
`
