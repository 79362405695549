import styled from 'styled-components'

const Container = styled.div`
  width: 100%;

  .background {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding-top: 30rem;
  }

  .main-title {
    font-size: 7rem;
    line-height: 8rem;
    color: ${({ theme }) => theme.blue};
    max-width: 90rem;
    margin: 0 auto;
    text-align: center;
  }

  .team-image {
    width: 100%;
    display: block;
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .about {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacingL};
    padding: ${({ theme }) => theme.spacingXL};

    &__text {
      width: 40%;

      .text {
        margin-top: ${({ theme }) => theme.spacingM};
      }
    }
    &__video {
      width: 60%;

      iframe {
        width: 100%;
        height: calc(50vw / 1.77);
        max-width: 75rem;
        max-height: calc(75rem / 1.77);
      }
    }
  }

  .team {
    padding: 6rem 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    .title {
      color: ${({ theme }) => theme.blue};
      text-align: center;
      margin-bottom: 3rem;
    }

    .team-grid {
      margin: ${({ theme }) => theme.spacingM} 0;
      padding: 0 ${({ theme }) => theme.spacingM};
    }
  }

  .container-small{
    max-width: 110vh !important;
    margin-top: 5vh !important;
    margin-bottom: 10vh !important;
  }

  .slick-slider .slick-next:before, .slick-slider .slick-prev:before{
    color:  ${({ theme }) => theme.blue};
    background-color:  ${({ theme }) => theme.gold};
    font-size: 4vh;
  }

  .slick-slider{
   
    .slick-next, .slick-prev{
      width: 3vh;
      height: 3vh;
      z-index: 99;
    }
    .slick-next {
      right: 5vh;
    }
    .slick-prev {
      left: 5vh;
    }
    svg:hover{
      transition: all 0.3s ;
      path{
        fill:  ${({ theme }) => theme.gold};
        transition: all 0.3s ;
      }
    }
  }

  .container-slide{
    position: relative;
    h3{
      position: absolute;
      top: 40vh;
      left: 7rem;
      font-size: 1.9rem;
      color: white;
      text-transform: uppercase;
    }
    h2{
      position: absolute;
      top: 43vh;
      left: 7rem;
      font-size: 5.5rem;
      color: white;
      font-weight: 400;
    }
    .description{
      position: absolute;
      top: 53vh;
      left: 7rem;
      font-size: 2rem;
      color: white;
      font-weight: 400;
      width: 27%;
      text-align: justify;
    }
    button{
      position: absolute;
      top: 70vh;
      left: 7rem;
    }
    img{
      height: 100vh;
      position: relative;
      z-index: -1;
    }
  }

  .team-member{
    max-width: 90rem !important;
  }

  @media screen and (max-width: 1000px) {

    .team{
      padding: 10vw 0 10vw 0;
    }

    .team .team-grid {
      padding: 0 ${({ theme }) => theme.spacingXS};
    }

    .main-title {
      font-size: 5rem;
      line-height: 1;
      max-width: 85%;
    }

    .about {
      flex-direction: column;
      padding: 0;
      padding-top: 15vw;
      padding-bottom: 15vw;

      &__text {
        width: 85%;
        text-align: center;
      }

      &__video {
        width: 100%;
        text-align: center;

        iframe {
          height: calc(85vw / 1.77);
        }
      }
    }
    .slick-next{
      right: 0vh !important;
    }
    .slick-prev{
      left: 0vh !important;
    }

    .slick-next, .slick-prev{
      width: 2vh !important;
      height: 2vh !important;
    }
  }

  @media screen and (max-width: 500px) {
    .about {

      &__text {
        width: 100%;
      }
    }
  }
`

export const Styled = {
  Container,
}
